.terms {
  padding: 7.5em 0 9.375em;

  .title {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 1.5em;
  }
  .para_wrapper {
    margin-bottom: 3.75em;
    &:last-child {
      margin-bottom: 0;
    }
    .section_heading32 {
      font-weight: 600;
      margin-bottom: 0.75em;
      text-transform: uppercase;
    }
    .section_desc20 {
      margin-bottom: 1.2em;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        color: rgba($color: #000000, $alpha: 0.6);
        font-weight: 600;
      }
    }
    // .sub_heading32 {
    //   text-transform: uppercase;
    // }

    .sub_heading {
      color: rgba($color: #000000, $alpha: 1);
      font-size: 1.5em;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      letter-spacing: 0px;
      line-height: 1.5;
      margin-bottom: 1em;
    }
    .list_wrapper {
      padding-left: 1.25em;
      li {
        color: rgba($color: #000000, $alpha: 0.6);
        font-size: 1.25em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.7;
        margin-bottom: 1.2em;
        list-style: disc;
        &::marker {
          color: #bb9532;
        }
        span {
          color: #000000;
          font-weight: 600;
        }
      }
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  .terms {
    padding: 6em 0 8.4em;
    .para_wrapper {
      margin-bottom: 6em;
      .section_heading32 {
        margin-bottom: 1.1em;
      }
      .section_desc20 {
        margin-bottom: 1.25em;
      }
      .sub_heading {
        font-size: 1.6em;
        letter-spacing: 0px;
        line-height: 1.625;
        margin-bottom: 1.375em;
      }
    }
  }
}
