@import '../../styles/common.scss';

.com_banner_container {
  position: relative;
  overflow-x: clip;

  .banner {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .breadcrumb {
    position: absolute;
    top: 11.6875em;
    left: 5em;
    z-index: 2;

    .home_icon {
      width: 100%;
      max-width: 1.3em;
    }

    .link_wrapper {
      display: flex;
      align-items: center;
    }

    .link {
      color: #ffffff;
      opacity: 0.8;
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: normal;
    }

    .space {
      margin: 0 0.25em;
    }

    .disabled {
      pointer-events: none;
    }
  }

  .content_wrapper {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
    text-align: center;

    .banner_heading {
      color: #ffffff;
      font-size: 6.25em;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 1.25;
      margin-top: 0.65em;
      text-align: center;
    }
  }
}

@media (max-width: 991px) {
  .com_banner_container {
    .breadcrumb {
      top: 8em;
    }

    .content_wrapper {
      .banner_heading {
        font-size: 4.5em;
        margin-top: 0.7em;
      }
    }
  }
}

@media (max-width: 767px) {
  .com_banner_container {
    .breadcrumb {
      top: 10em;
      left: 1.5em;

      .link {
        font-size: 1.4em;
        letter-spacing: 0px;
        line-height: normal;
      }
    }

    .content_wrapper {
      padding: 0 1.5em;
      top: 60%;

      .banner_heading {
        font-size: 5.6em;
        letter-spacing: 0px;
        line-height: 1.1785;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .com_banner_container {
    .breadcrumb {
      display: none;

      .link {
        font-size: 1.2em;
      }
    }

    .content_wrapper {
      .banner_heading {
        font-size: 4em;
      }
    }
  }
}