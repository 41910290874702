@import "../../styles/common.scss";

.info_tech_sec1 {
  padding: 7.5em 0 0;
}

.info_tech_sec2 {
  padding: 7.5em 0 11.875em;
  .venture_main_row {
    .venture_main_col {
      .venture_mainbox {
        .venture_row {
          .venture_col {
            .venture_box {
              .venture_img {
                object-fit: initial;
                height: auto;
              }
              .content_wrapper {
                height: 100%;
                max-height: 8.1875em;
                overflow-x: auto;
                padding-right: 0.8em;
                &::-webkit-scrollbar {
                  border-radius: 50px;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1536px) {
  .info_tech_sec2 {
    .venture_main_row {
      .venture_main_col {
        .venture_mainbox {
          .venture_row {
            .venture_col {
              .venture_box {
                .content_wrapper {
                  max-height: 9.65em;
              
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .info_tech_sec2 {
    .venture_main_row {
      .venture_main_col {
        .venture_mainbox {
          .venture_row {
            .venture_col {
              .venture_box {
                .content_wrapper {
                  max-height: 8.5em;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .info_tech_sec2 {
    .venture_main_row {
      .venture_main_col {
        .venture_mainbox {
          .venture_row {
            .venture_col {
              .venture_box {
                .content_wrapper {
                  max-height: 7em;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .info_tech_sec1 {
    padding: 6em 0 0;
    .my_container {
      padding: 0 16px;
    }
  }
  .info_tech_sec2 {
    padding: 6em 0 6em;
    .my_container {
      padding: 0 16px;
    }
    .venture_main_row {
      .venture_main_col {
        .venture_mainbox {
          .venture_row {
            .venture_col {
              .venture_box {
                .content_wrapper {
                  max-height: 100%;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
