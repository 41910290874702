.investment {
  padding: 7.5em 0 9.375em;

  .title {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 1.5em;
  }
  .desc {
    font-size: 1.25em;
    opacity: 0.6;
    p {
      margin-bottom: 1em;
    }
  }
}
