.category-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .category-filters {
    display: flex;
    gap: 3.5em;
    // justify-content: space-between;
    margin-bottom: 5em;
    padding-right: 1em;
    padding-bottom: 1.875em;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    &::-webkit-scrollbar {
      // display: none;
      background-color: rgba($color: #bb9532, $alpha: 0.1);
      height: 4px;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50px;
    }

    .category-button {
      background-color: transparent;
      border: 1px solid #bb9532;
      padding: 0.821em 1.635em;
      font-size: 1.5em;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
      letter-spacing: 0px;
      line-height: 1.334;
      white-space: nowrap;
      cursor: pointer;
      text-transform: uppercase;
      border-radius: 50px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #f0f0f0;
      }

      &:focus {
        outline: none;
      }

      &.active {
        background-color: #bb9532;
        color: #fff;
        border-color: #bb9532;
      }
    }
  }

  .category-images {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)); // 3 images per row
    gap: 2.625em;
    width: 100%;
    // max-width: 1200px; // Limit max width to ensure 3 images per row
    margin: 0 auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.fade {
      opacity: 0;
    }
  }

  @media (max-width: 1024px) {
    .category-filters {
      gap: 3em;
    }

    .category-images {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 992px) {
    .category-filters {
      justify-content: center;
      .category-button {
        appearance: none;
        -webkit-appearance: none;
        background-color: #ffffff;
        background: url("../../images/general/dropdown_arrow_black.svg") no-repeat;
        background-size: 0.9em;
        background-position: calc(100% - 1.635em) center;
        color: #000000;
        font-size: 2em;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 1.6;
        padding: 0.821em 3em 0.821em 1.635em;
        width: 100%;
        max-width: 15.7em;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 4em;

    .category-images {
      grid-template-columns: repeat(2, 1fr);
    }
  }



  @media (max-width: 576px) {
    padding: 2em 2em 5em;
    .category-images {
      grid-template-columns: 1fr;
    }
  }

  // @media (max-width: 431px) {
  //   .category-filters {
  //     width: 100%;
  //     padding: 0;
  //     justify-content: flex-start;
  //   }
  // }
}

.category-card {
  position: relative;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &__image {
    width: 100%;
    // height: 34.9375em;
    display: block;
    // object-fit: cover;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
}
