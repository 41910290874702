.careers {
  padding: 7.5em 0 9.375em;
  display: flex;
  justify-content: space-between;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-text-fill-color: inherit !important;
  }

  h1 {
    font-size: 2em;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-bottom: 1em;
    flex: 0 0 25%;
  }

  .careers-content {
    flex: 0 0 75%;
  }

  .description {
    font-size: 1.25em;
    line-height: 1.5;
    margin-bottom: 2em;
    color: #666;
  }

  form {
    .form-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2.5em;
      row-gap: 2.25em;
      .input_wrapper {
        position: relative;
      }
    }

    .msg_wrapper {
      position: relative;
      .error_text {
        margin-top: 0;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 1em 0;
      border: none;
      border-bottom: 1px solid #ddd;
      font-size: 1.5em;
      background-color: transparent;

      &::placeholder {
        font-size: 0.8em;
      }

      &:focus {
        outline: none;
        border-bottom-color: #d4af37;
      }
    }

    textarea {
      margin-top: 1.5em;
      resize: none;
    }

    .file-input {
      position: relative;

      input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5em;
        padding: 1.5em 0;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        color: #757575;
        font-size: 1.2em;

        svg {
          width: 1.042em;
          height: 1.042em;
          color: #000;
        }
      }
    }

    .submit-btn {
      margin-top: 3.75em;
    }
  }
}

.recaptcha-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3.75em;
  position: relative;

  .error_text {
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .careers {
    flex-direction: column;
  }

  .careers form .form-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .careers {
    .description {
      font-size: 1.6em;
      margin-bottom: 0.8125em;
    }
    form {
      .form-grid {
        column-gap: 0;
        row-gap: 1.25em;
      }
      .file-input {
        label {
          svg {
            width: 1.08em;
            height: 1.08em;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .recaptcha-container {
    transform: scale(0.9);
    transform-origin: 0;
  }
}

@media (max-width: 400px) {
  .recaptcha-container {
    transform: scale(0.85);
  }
}

@media (max-width: 370px) {
  .recaptcha-container {
    transform: scale(0.75);
  }
}

@media (max-width: 335px) {
  .recaptcha-container {
    transform: scale(0.7);
  }
}
