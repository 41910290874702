.vaccodion_container {
  // padding-right: 4.3em;
  .vaccordion_list_wrapper {
    display: flex;
    overflow: hidden;
    height: auto;
    width: 100%;
    margin: 5em 0 0;
    padding: 0;
    list-style: none;
    min-height: 51.3125em;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);

    .vaccordion_list {
      //   width: fit-content;
      flex: 1;
      list-style: none;
      transition: flex 500ms ease-out;
      cursor: pointer;
      display: flex;
      //   min-width: 12.75em;
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        background-color: #000000;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0.25;
      }

      &.selected {
        flex: 18vw;
        &::before {
          opacity: 0;
        }
        .maintitle {
          display: none;
        }
        .desc_wrapper {
          display: block;
          left: 0;
          height: auto;
          transition: 0.3s ease;
        }
        // .bg_img {
        //   object-position: center 40%;
        // }
      }

      .vaccordion_title_wrapper {
        display: flex;
        align-items: flex-end;
        padding: 2em 1.25em;
        .maintitle {
          //   width: 100%;
          color: #ffffff;
          font-size: 3em;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: normal;
          writing-mode: vertical-rl;
          transform: rotate(180deg);
        }
      }

      .desc_wrapper {
        display: none;
        background-color: rgba($color: #ffffff, $alpha: 0.95);
        width: 30.4375em;
        position: absolute;
        bottom: 0;
        height: 0;
        right: -100%;
        padding: 2.5em 1.25em 2.5em 2.5em;
        transition: width 0.3s ease;
        .sub_title {
          color: #000000;
          font-size: 3em;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: normal;
          margin-bottom: 0.4167em;
        }
        .section_desc20 {
          color: #000000;
          padding-right: 2.5em;
        }
        .arrow_wrapper {
          border: 1px solid #bb9532;
          border-radius: 50%;
          width: 100%;
          max-width: 3.75em;
          height: 3.75em;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          .right_arrow {
            display: block;
            width: 100%;
            max-width: 1.25em;
          }
        }
      }

      .bg_img {
        object-fit: cover;
        // right: 0;
        // bottom: 0;
      }
    }
  }
}

@media (max-width: 1536px) {
  .vaccodion_container {
    .vaccordion_list_wrapper {
      .vaccordion_list {
        .desc_wrapper {
          width: 34em;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .vaccodion_container {
    .vaccordion_list_wrapper {
      .vaccordion_list {
        .desc_wrapper {
          width: 30.4375em;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .vaccodion_container {
    padding-right: 0;
    .vaccordion_list_wrapper {
      .vaccordion_list {
        .desc_wrapper {
          max-width: 95%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .vaccodion_container {
    .vacc_row {
      margin: 0 auto;
      padding-top: 2em;
      .vacc_col {
        padding: 0;
        margin-top: 2em;
        .vacc_box {
          position: relative;
          &::before {
            content: "";
            background: linear-gradient(180deg, #00000000, #000000);
            width: 100%;
            height: 9.8em;
            position: absolute;
            left: 0;
            bottom: 0;
          }
          .box_img {
            width: 100%;
            max-width: 100%;
           
          }
          .head_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            left: 0;
            bottom: 1.5em;
            width: 100%;
            max-width: 100%;
            padding: 0 1.5em;
            .section_heading32 {
              color: #ffffff;
            }
          }
          .right_arrow {
            width: 100%;
            max-width: 3.75em;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .vaccodion_container {
    .vacc_row {
      padding-top: 0.8em;
      .vacc_col {
        margin-top: 2em;
        .vacc_box {
          .right_arrow {
            max-width: 2.8em;
          }
          .head_wrapper {
            padding: 0 1.2em;
            bottom: 1.2em;
          }
        }
      }
    }
  }
}
