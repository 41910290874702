@import "../../styles/common.scss";

.health_sec1 {
  padding: 7.5em 0 0;
}

.health_sec2 {
  padding: 7.5em 0 11.875em;
  .venture_main_row {
    .venture_main_col {
      .venture_mainbox {
        .venture_row {
          .venture_col {
            .venture_box {
              .section_heading32 {
                line-height: 1.25;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .health_sec1 {
    padding: 6em 0 0;
    .my_container {
      padding: 0 16px;
    }
  }
  .health_sec2 {
    padding: 6em 0 6em;
    .my_container {
      padding: 0 16px;
    }
  }
}
