.sports-venture {
  padding: 7.5em 0 11.875em;

  .section-card {
    margin-bottom: 7.5em;
    display: flex;
    align-items: flex-start;

    .section-title {
      margin-top: 0.25em;
      font-size: 2em;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      color: #333;
      flex: 0 0 25%;
      text-transform: uppercase;
      padding-right: 1.1em;
    }

    .section-content {
      flex: 0 0 75%;
      font-size: 1.25em;
      line-height: 1.6;
      color: #666;
    }

    &.team-section {
      .section-content {
        width: 100%;
      }
    }
  }

  .section-card:last-child {
    margin-bottom: 0;
  }

  .teams-container {
    display: flex;
    gap: 1.625em;
  }

  .team-card {
    // flex: 1;
    width: 50%;
    border: 1px solid #929191;
    overflow: hidden;

    .team-logo {
      width: 100%;
      padding: 1em;
      height: auto;
      display: block;
    }

    .team-info {
      padding: 1.375em;
      border-top: 1px solid #929191;

      .team-name {
        font-size: 1.6em;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 0.75em;
        color: #000000;
      }

      .team-description {
        font-size: 0.8em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.75;
        color: rgba($color: #000000, $alpha: 0.6);
      }
    }
  }

  // Specific styling for the OUR GROUND section
  .section-card:nth-child(4) {
    .section-content {
      position: relative;
      // padding-top: 2em;

      p {
        margin-top: 1em;
      }

      // &::before {
      //   content: 'SARASWATI SPORTS COMPLEX';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   background-color: #c0e3f7;
      //   padding: 0.25em 0.5em;
      //   font-size: 0.8em;
      //   font-weight: 700;
      //   color: #333;
      // }
      .sec_img {
        width: 100%;
        max-width: 21.2em;
      }
    }
  }

  .card_img_row {
    margin: 0 auto;
    padding-top: 2em;
    .card_img_col {
      padding: 0;
      &:nth-child(2n + 1) {
        padding-right: 0.65em;
      }
      &:nth-child(2n + 2) {
        padding-left: 0.65em;
      }
      .img_wrapper {
        border: 1px solid rgba($color: #000000, $alpha: 0.4);
        padding: 1.1em;
        width: 100%;
        max-width: 100%;
        .card_img {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }
      }
    }
  }

  .log_row {
    margin: 0 auto;
    padding-top: 2em;
    column-gap: 0.6em;
    row-gap: 1em;
    .logo_col {
      padding: 0.8em;
      border: 1px solid rgba($color: #000000, $alpha: 0.4);
      max-width: calc(33.333% - 0.45em);
      .logo_wrapper {
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        height: 100%;
        padding: 1.3em 1.3em;
        .card_img {
          display: block;
          max-width: 13.4em;
          margin: auto;
        }
      }
    }
  }
}

@media (max-width: 1536px) {
  .sports-venture {
    .team-card {
      .team-info {
        .team-description {
          font-size: 1em;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .sports-venture {
    .log_row {
      .logo_col {
        .logo_wrapper {
          .card_img {
            max-width: 10em;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .sports-venture {
    .log_row {
      .logo_col {
        max-width: calc(50% - 0.4em);
        .logo_wrapper {
          .card_img {
            max-width: 10em;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sports-venture {
    .section-card {
      flex-direction: column;

      .section-title,
      .section-content {
        flex: 0 0 100%;
      }

      .section-title {
        margin-bottom: 1em;
        padding-right: 0;
        br {
          display: none;
        }
      }
    }

    .teams-container {
      flex-direction: row;
    }

    .log_row {
      .logo_col {
        .logo_wrapper {
          .card_img {
            max-width: 11em;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sports-venture {
    padding: 6em 0 8.4em;
    .section-card {
      margin-bottom: 6em;
      :nth-child(4) {
        .section-content {
          .sec_img {
            max-width: 100%;
          }
        }
      }
      .section-content {
        font-size: 1.6em;
      }
    }

    .teams-container {
      flex-direction: column;
      gap: 20px;
    }

    .team-card {
      width: 100%;
      max-width: 100%;
      .team-info {
        .team-description {
          font-size: 1em;
        }
      }
    }

    .card_img_row {
      .card_img_col {
        margin-bottom: 1.25em;
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .img_wrapper {
          padding: 1.25em;
        }
      }
    }

    .log_row {
      column-gap: 0;
      row-gap: 0;
      .logo_col {
        max-width: calc(50% - 0.5em);
        margin-top: 1.25em;
        padding: 0.625em;
        &:nth-child(2n + 1) {
          margin-right: 0.4em;
        }
        &:nth-child(2n + 2) {
          margin-left: 0.4em;
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
        .logo_wrapper {
          padding: 1em;
          .card_img {
            max-width: 7em;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .sports-venture {
    .log_row {
      .logo_col {
        .logo_wrapper {
          .card_img {
            max-width: 5em;
          }
        }
      }
    }
  }
}
