.comm_cta_sec1 {
  .cta_link {
    background: transparent;
    border: none;
    border-radius: 50px;
    font-size: 1.375em;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0px;
    line-height: normal;
    text-align: center;
    display: flex;
    align-items: center;
    transition: 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 2;
    min-width: 11.2727em;
    max-width: 11.2727em;
    position: relative;
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      transition: 0.3s ease;
      .cta_text {
        background-color: transparent;
        // border: 2px solid transparent;
        transition: 0.2s ease;
        &::before {
          width: 100%;
          transition: width 0.2s ease;
        }
      }
      .arrow_wrapper {
        position: relative;
        // border: 2px solid transparent;
        &::before {
          width: 100%;
          transition: width 0.1s ease;
          transition-delay: 0.2s;
        }
      }
      .white_right_arrow {
        margin-left: 0.25em;
        transition: 0.1s ease;
        transition-delay: 0.2s;
        background-color: transparent;
      }
    }
    .cta_text {
      display: inline-block;
      border: 1px solid #bb9532;
      border-radius: 50px;
      padding: 0.528em 1.38em;
      white-space: nowrap;
      transition: 0.4s ease;
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        background-color: #bb9532;
        border-radius: 50px;
        width: 0;
        max-width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transition: width 0.2s ease;
        transition-delay: 0.1s;
      }
    }
  }
  .arrow_wrapper {
    border: 1px solid #bb9532;
    border-radius: 50%;
    width: 100%;
    max-width: 2.7273em;
    height: 2.7273em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0.182em;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      background-color: #bb9532;
      border-radius: 50%;
      width: 0;
      max-width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      transition: width 0.1s ease;
    }
    .white_right_arrow {
      width: 100%;
      max-width: 0.9168em;
      transition: 0.1s ease;
    }
  }
}

@media (max-width: 767px) {
  .comm_cta_sec1 {
    .cta_link {
      font-size: 1.6em;
      letter-spacing: 0px;
      line-height: normal;
      min-width: auto;
      min-width: 12.5em;
      max-width: 12.5em;
      .cta_text {
        padding: 0.777em 1.3em;
      }
    }
    .arrow_wrapper {
      max-width: 3.25em;
      width: 3.25em;
      height: 3.25em;
      margin: 0 0 0 0.125em;
      .white_right_arrow {
        max-width: 1.04em;
      }
    }
  }
}
