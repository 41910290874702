.header {
  position: fixed;
  top: 1.5em;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 9;
  padding: 0 1.875em;
  .navlinks_wrapper {
    position: relative;
    background-color: rgba(255, 255, 255, 0.057);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    // box-shadow: 30px 50px 50px #00000029;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff99;
    border-radius: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s ease;
    padding: 1.178em 3.125em;
    &.active {
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      transition: 0.5s ease;
      .link_list {
        .link_item {
          .nav_tab {
            color: #000000;
          }
        }
      }
      .comm_cta_sec1 {
        .cta_link {
          color: #000000;
          .cta_text {
            background: #ffffff;
          }
        }
        .arrow_wrapper {
          background: #ffffff;
          transition: 0.5s ease;
        }
      }
    }
    .logo_wrapper {
      width: fit-content;
      display: inline-block;
      .logo_img {
        position: relative;
        max-width: 14.8125em;
        display: block;
      }
    }
    .link_wrapper {
      display: flex;
      align-items: center;
    }
    .link_list {
      border-radius: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 1.25em;
      .link_item {
        .dropdown {
          position: relative;
          &.active {
            .dropdown_menu_wrapper {
              // display: block;
              transition: 0.35s ease;
              transform: scaleY(1);
            }
          }
          .dropdown_arrow {
            width: 100%;
            max-width: 0.57em;
            margin: 0 0 0 0.4em;
            vertical-align: super;
            position: relative;
            top: 0.2em;
          }
          .dropdown_menu_wrapper {
            background-color: #ffffff;
            position: absolute;
            top: 4.5em;
            left: 0;
            // min-width: 31em;
            min-width: 29.125em;
            // display: none;
            transition: 0.3s ease;
            padding: 2.5em 0;
            transform: scaleY(0);
            transform-origin: top;
          }
          .dropdown_menu {
            position: relative;
            padding: 0 0 1.5em 0;
            &:hover {
              .arrow_wrapper {
                display: flex;
              }
            }
            &:last-child {
              padding-bottom: 0;
              .dropdown_menu_link {
                border-bottom: none;
              }
            }
            .arrow_wrapper {
              border: 1px solid #bb9532;
              border-radius: 50%;
              width: 100%;
              max-width: 2.125em;
              height: 2.125em;
              display: none;
              justify-content: center;
              align-items: center;
              transition: 0.5s ease;
              position: absolute;
              right: 2.5em;
              top: 0;
              // top: 50%;
              // transform: translateY(-50%);
              .arrow {
                width: 100%;
                max-width: 0.75em;
              }
            }
          }
          .dropdown_menu_link {
            background: #ffffff;
            color: #000000;
            display: block;
            text-decoration: none;
            padding: 0 1.667em;
            font-size: 1.5em;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.334;
            border-radius: 0;
            transition: 0.5s ease;
            &.active {
              transition: 0.5s ease;
            }
          }
        }
        .nav_tab {
          color: #ffffff;
          font-size: 1.25em;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 1.2;
          white-space: nowrap;
          padding: 2.5em 0 2.5em;
          margin-right: 3em;
          cursor: pointer;
          position: relative;
          &::before {
            content: "";
            background-color: #bb9532;
            border-radius: 50px;
            display: block;
            height: 2px;
            width: 0;
            position: absolute;
            left: 0;
            bottom: 2em;
            transition: width 0.5s ease;
          }
        }
        &:last-child .nav_tab {
          margin-right: 0;
          // &.active {
          //   &::before {
          //     width: 100%;
          //   }
          // }
        }
        .nav_tab.active {
          &::before {
            // width: calc(100% - 3em);
            width: 100%;
            transition: width 0.5s ease;
          }
        }
      }
    }
    .comm_cta_sec1 {
      .cta_link {
        color: #ffffff;
        min-width: 11.5em;
        max-width: 11.5em;
        transition: 0.5s ease;
        // &:hover {
        //   .cta_text {
        //     background: transparent;
        //   }
        //   .arrow_wrapper {
        //     background: transparent;
        //   }
        // }
        .cta_text {
          background: #000000;
          border: 1px solid #bb9532;
          .cta {
            position: relative;
            z-index: 2;
          }
          &::before {
            z-index: 1;
          }
        }
      }
      .arrow_wrapper {
        background: #000000;
        border: 1px solid #bb9532;
        transition: 0.1s ease;
        &::before {
          z-index: 1;
        }
        .white_right_arrow {
          z-index: 2;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .header {
    top: 1.4em;
    padding: 0;
    .my_container {
      padding: 0 12px;
      max-width: 100%;
    }
    .hamburger_link_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .download_icon {
        width: 100%;
        max-width: 5em;
        margin-left: 0.863em;
        display: block;
      }
    }
    .hamburger_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba($color: #ffffff, $alpha: 0.057);
      // box-shadow: 30px 50px 50px #00000029;
      box-shadow: 0px 3px 6px #00000029;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border-radius: 60px;
      border: 0.5px solid #ffffff99;
      padding: 1.134em 1.9em 1.134em 1.2em;
      width: 100%;
      max-width: 100%;
      &.active {
        background-color: #ffffff;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
      }
    }
    .logo_wrapper {
      .mb_logo {
        width: 100%;
        max-width: 10.5em;
      }
    }

    .mobile_header_wrapper {
      background-color: #ffffff;
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      transition: 0.3s left;
      &.active {
        left: 0;
      }
      .logo_wrapper {
        border: 0.5px solid transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.2em 1.9em 1.134em 1.6em;
        width: 100%;
        max-width: 100%;
        .mb_logo {
          width: 100%;
          max-width: 10.5em;
        }
      }
      .mobile_links_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        width: 100%;
        margin: 4.9em 0 0;
        padding: 0;
        &::-webkit-scrollbar {
          //   width: 5px;
          width: 0;
        }
        .nav_item {
          width: 100%;
        }
        .nav_link_wrapper {
          position: relative;
          // width: fit-content;
          // margin: 0 auto;
        }
        .dropdown_box {
          display: none;
          transition: 0.5s ease;
          padding: 0.4em 1.6em 2em;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
          &.active {
            color: #bb9532;
            display: block;
            transition: 0.5s ease;
          }
          .dropdown_list_item {
            &:last-child .drop_nav_link {
              margin-bottom: 0;
            }
          }
          .drop_nav_link {
            color: #000000;
            display: block;
            font-size: 1.6em;
            font-weight: 600;
            letter-spacing: 0px;
            line-height: normal;
            opacity: 0.6;
            margin-bottom: 1em;
            &.active {
              color: #bb9532;
              transition: 0.5s ease;
              opacity: 1;
            }
          }
        }
        .nav_link {
          background: transparent;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
          color: #000000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 2em;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 1.2;
          text-decoration: none;
          padding: 1em 0.8em;
          .arrow_wrapper {
            border: 1px solid #bb9532;
            border-radius: 50%;
            width: 1.7em;
            height: 1.7em;
            display: flex;
            justify-content: center;
            align-items: center;
            .arrow {
              width: 100%;
              max-width: 0.55em;
              display: block;
              transition: 0.3s ease;
              transform: rotate(90deg);
            }
          }
        }
        .nav_link.active {
          color: #bb9532;
          .arrow {
            display: none;
            transition: 0.3s ease;
          }
        }
        .nav_link.dropActive {
          color: #000000;
          border-bottom: none;
          .arrow {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .hamburger_lines {
      position: absolute;
      top: 50%;
      right: 3.1em;
      transform: translateY(-50%);
      z-index: 5;
      width: 3.2em;
      height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .line,
      .line1,
      .line2,
      .line3 {
        height: 1px;
        width: 100%;
        border-radius: 1em;
        background-color: #ffffff;
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }
      &.active {
        width: 2em;
        height: 2em;
        right: 1.6em;
        .line {
          background-color: #000000;
          height: 1.5px;
        }
        .line1 {
          max-width: 100%;
          //   max-width: 2em;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: translateY(0.4em) rotate(135deg);
        }

        .line2 {
          max-width: 100%;
          opacity: 0;
        }

        .line3 {
          max-width: 100%;
          //   max-width: 2em;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: translateY(-1.5em) rotate(-135deg);
        }
      }
      &.black {
        .line {
          background-color: #000000;
        }
      }
    }
  }
}
