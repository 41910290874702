.news-center {
  padding: 7.5em 4em;

  .news-center-cards-media,
  .news-center-cards-awards,
  .news-center-cards-press {
    margin-bottom: 2em;

    .news_row {
      .news_col {
        cursor: pointer;
        .news_box {
          .head_wrapper {
            padding: 1.25em 0 1em;
            .section_heading32 {
              color: rgba($color: #000000, $alpha: 0.6);
              font-size: 1.25em;
              font-family: "Montserrat", sans-serif;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 1.8125em;
            }
          }
          .section_desc20 {
            color: #000000;
            font-size: 1.25em;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 1.7em;
          }
        }
      }
    }

    .btn-container {
      width: 100%;
      text-align: center;
    }

    .load-more {
      margin: 3em auto;
      text-decoration: underline;
      font-weight: 600;
      font-size: 1.375em;
      border: none;
      background-color: transparent;
    }
  }

  @media (max-width: 768px) {
    padding: 4em 0;
  }
}

@media (max-width: 767px) {
  .news-center {
    .news-center-cards-media,
    .news-center-cards-awards,
    .news-center-cards-press {
      .news_row {
        .news_col {
          .news_box {
            .head_wrapper {
              padding: 1.25em 0 1em;
              .section_heading32 {
                font-size: 1.6em;
                letter-spacing: 0px;
                line-height: 1.625;
              }
            }
            .section_desc20 {
              font-size: 1.6em;
              letter-spacing: 0px;
              line-height: 1.625;
            }
          }
        }
      }
    }
  }
}
